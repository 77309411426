import React, { useEffect } from 'react';
import { PageProps } from 'gatsby';

const CcwRedirect: React.FC<PageProps> = () => {
  useEffect(() => {
    if (window !== undefined) {
      const redirectUrl = process.env.LANDING_PAGE_DOMAIN
        ? `https://app.${process.env.LANDING_PAGE_DOMAIN}/ccw`
        : 'https://app.tuune.com/ccw';

      window.location.assign(redirectUrl);
    }
  }, []);

  return <div />;
};

export default CcwRedirect;
